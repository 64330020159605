// Imports
  @import 'modules/_all';
// END Imports

//CONTAINER BOXES HOME
//====================
.dd-half-box {
  width: 50%;
  vertical-align: top;

  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

.dd-half-box:first-of-type {
  padding-right: 20px;
}

.dd-half-box:last-of-type {
  padding-left: 20px;
}

.dd-half-box:first-of-type,
.dd-half-box:last-of-type {
  @media screen and (max-width: $tablet) {
    padding: 0;
  }
}
//end CONTAINER BOXES HOME
//====================

//---------------//----------------//

//ACORDDION HORIZONTAL
//====================
.dd-section-accordion {
  width: 100%;
  
  @media screen and (max-width: 900px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 435px) {
    display: none;
  }
}

.dd-accordion-horizontal {
	list-style:none;
	margin-bottom: 72px;
	padding:0;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;

  .dd-panel {
  	width: 30.8px;
  	overflow:hidden;
  	text-decoration:none;
  	font-size:16px;
  	line-height:1.5em
  }

  .dd-title {
  	width:31px;
  	height:200px;
  	float:left;
  	cursor:pointer;
    position: relative;
  }

  .dd-panel:nth-child(even) .dd-title{
    background-color: #e6e6e6;
  }

  .dd-panel:nth-child(odd) .dd-title{
    background-color: #ccc;
  }

  .dd-title div {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    ms-transform: rotate(-90deg);
    -sand-transform: rotate(90deg);
    transform: rotate(-90deg);
    height: 29px;
    width: 210px;
    position: absolute;
    color: #C71B1B;
    top: 86px;
    left: -88px;
    text-align: center;
    position: absolute;
    display: block;
  }

  .dd-panel-content {
    padding-left: 41px;
    text-align: center;
  }
}
//end ACORDDION HORIZONTAL
//====================

//---------------//----------------//

//BLOCK NEWS
//====================
.dd-block-production {
  margin-bottom: 45px;

  .dd-thumb {
    width: 44%;

    @media screen and (max-width: $tablet) {
      width: 30%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .dd-info {
    width: 56%;
    padding-left: 6%;

    @media screen and (max-width: $tablet) {
      width: 70%;
      padding-left: 5%;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      padding-left: 0;
    }
  }

  .dd-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 12px;
  }
}
//end BLOCK NEWS
//====================

//---------------//----------------//

//VIDEO LIST
//====================
.dd-list-video {
  margin-bottom: 45px;

  li {
    @include calc('width', '33.333% - 20px');
    vertical-align: top;
    margin: 0 30px 0 0;

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  li:last-child {
    margin-right: 0;
  }
}

.dd-list-video .dd-thumb {
  width: 100%;
  margin-bottom: 4px;

  @media screen and (max-width: $mobile) {
    width: 40%;
  }
}

.dd-list-video .dd-info {
  width: 100%;

  @media screen and (max-width: $mobile) {
    width: 60%;
    padding-left: 19px;
  }
}

.dd-btn-see-all {
  bottom: 2px;
}
//end VIDEO LIST
//====================

//---------------//----------------//

//SECTION SEARCH
//====================
.dd-block-search {
  background-color: #E6E6E6;
  margin-bottom: 50px;
  @include border-radius(10px);

  .dd-title-search {
    background-color: $red;
    color: $white;
    text-transform: uppercase;
    font-size: 17px;
    letter-spacing: 2px;
    padding: 4px 0 4px 21px;
    @include border-radius(10px);
  }

  .dd-content {
    padding: 14px 18px 7px 18px;
  }

  .dd-label {
    margin-left: 10px;

    @media screen and (max-width: $large) {
      font-size: 12px;
      margin-left: 1px;
    }

    @media screen and (max-width: 900px) {
      font-size: 14px;
      margin-left: 8px;
    }

    @media screen and (max-width: $tablet) {
      margin-left: 10px;
    }
  }

  .dd-box-radio {
    width: 33.333%;
    padding-left: 40px;
    margin-bottom: 11px;

    @media screen and (max-width: $large) {
      padding-left: 13px;
    }

    @media screen and (max-width: 900px) {
      width: 50%;
      padding-left: 0;
    }

    @media screen and (max-width: $tablet) {
      width: 33.333%;
      padding-left: 40px;
    }

    @media screen and (max-width: 530px) {
      width: 50%;
      padding-left: 0;
    }

    @media screen and (max-width: 360px) {
      width: 100%;
    }

    &:nth-child(2),
    &:nth-child(5){
      padding-left: 0;
    }
  }
}
//end SECTION SEARCH
//====================

//---------------//----------------//

//BANNER
//====================
.dd-block-banner {
  margin-bottom: 48px;

  .dd-box-banner {
    background-color: $gray--12;
  }

  .dd-box-text {
    width: 100%;
    left: 0;
    bottom: 0;
    font-size: 14px;
    color: $white;
    padding: 6px 11px;
    line-height: 20px;

    @media screen and (max-width: $tablet) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
//end BANNER
//====================

//---------------//----------------//

//LOGO LIST
//====================
.dd-wrap-logo-list {
  width: 100%;
  padding-top: 15px;
  border-top-color: $gray--6;
  border-top-style: solid;
  border-top-width: 1px;

  .bx-viewport {
    margin: 0 auto;

    @media screen and (max-width: $large) {
      width: 84% !important;
    }

    @media screen and (max-width: $tablet) {
      width: 80% !important;
    }

    @media screen and (max-width: $mobile) {
      width: 73% !important;
    }
  }

  .bx-wrapper .bx-controls-direction a {
    @media screen and (min-width: $large) {
      display: none !important;
    }
  }
}

.dd-logo-list {
  width: 100%;
  margin: 0 auto;
}
//end LOGO LIST
//====================

//---------------//----------------//

.dd-banner {
  vertical-align: top;

  @media screen and (max-width: $tablet) {
    width: 50%;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: $mobile) {
    width: 100%;

    &:nth-child(odd) {
      padding-right: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }
  }
}

.dd-title-section {
  @media screen and (max-width: $tablet) {
    font-size: 15px;
    padding-left: 0;
  }
}

//GENERAL
//====================

//Positions/Spaces
//====================
.dd-wrapper {
  padding-top: 40px;
}

//Text and Titles
//====================
.dd-title-section {
  @media screen and (max-width: $mobile) {
    padding-left: 0;
    font-size: 15px;
  }
}

//end GENERAL
//====================
